<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn text rounded color="primary" v-on="on">
				{{ $t('cv.addNewFem') }}
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('courses.makeAPublication') }}</span>
				<v-spacer />
				<v-btn icon color="secondary" @click="switchEditMode()">
					<v-icon class="mx-4">
						mdi-close
					</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field
						v-model="title"
						:label="$t('newsfeed.title')"
						:placeholder="$t('newsfeed.titleToRemember')"
						:rules="rules.title"
						outlined
						rounded
						hide-details="auto"
						class="pb-2"
					/>
					<v-textarea
						v-model="content"
						:label="$t('newsfeed.content')"
						:rows="1"
						:rules="rules.content"
						:counter="contentMaxLength"
						auto-grow
						outlined
						rounded
						hide-details="auto"
						class="pb-2"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded plain @click="callPublishNotice()">
					{{ $t('newsfeed.share') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'FeedNewNotice',
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			title: '',
			content: '',
			rules: {
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength })
				],
				content: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.contentMaxLength) || this.$t('rules.length', { length: this.contentMaxLength })
				]
			}
		}
	},
	computed: {
		titleMaxLength() {
			return 64
		},
		contentMaxLength() {
			return 1024 * 10
		},
		parentCode() {
			if (this.$route.path.includes('offer')) return 'offer'
			else if (this.$route.path.includes('event')) return 'event'
			else return ''
		}
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callPublishNotice() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.publishNotice({
				parent: this.parentCode,
				parentID: parseInt(this.$route.params.id),
				title: this.title,
				content: this.content
			}).then((data) => {
				if (data.success) {
					this.switchEditMode()
					this.title = ''
					this.content = ''
				}
			})
		},
		...mapActions('feed', ['publishNotice'])
	}
}
</script>

<style scoped></style>
